<template>
    <div class="login">
      <div class="container login_header">
          <div class="login_top flex_content">
              <div class="logo_icon" @click="toPage('index')">
                  <img src="../../assets/image/logo-white.png" alt="">
              </div>
          </div>
      </div>
      <div class="login_container">
          <div class="login_thumb"> 
              <div class="login_title">Welcome!</div>
                  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleFormRef" autoComplete="on" class="demo-ruleForm" label-position="top">
                      <div class="login_input">
                          <!-- <div class="input_label">E-mail</div> -->
                          <el-form-item label="E-mail" prop="email">
                            <!-- <div class="input_cont"> -->
                              <el-input v-model="ruleForm.email"></el-input>
                            <!-- </div> -->
                          </el-form-item>
                      </div>
                      <div class="login_input">
                          <!-- <div class="input_label">Password</div> -->
                          <el-form-item label="Password" prop="password">
                            <!-- <div class="input_cont"> -->
                              <el-input v-model="ruleForm.password" type="password" :show-password="true" ></el-input>
                            <!-- </div> -->
                          </el-form-item>
                      </div>
                      <div class="btn_group flex_sb">
                          <div class="default_button flex_content pointer white_button" @click="toPage('index')">Later</div>
                          <div class="default_button flex_content pointer red_button" @click="login()">Sing up</div>
                      </div>
              </el-form>
              <div class="login_up flex_content" >
                  Welcome back!<span class="pointer" @click="toPage('login')"> Login</span>
              </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'G2GLogin',
  
    data() {
      const validatePass = (rule, value, callback) => {
         
          if (value == "") {
            callback(new Error('This field is required'));
          } else {        
            callback();
          }
      }
      const validateEmail = (rule, value, callback) => {
          if (value == "") {
              callback(new Error('This field is required'));
          } else {       
              callback();
          }
      }
      
      return {
          ruleForm:{
             email:'',
             password:'', 
          },
          rules: {
            password: [{  validator: validatePass, trigger: 'blur' }],
            email: [{  validator: validateEmail, trigger: 'blur' }],
          }       
      };
    },
  
    mounted() {
      
    },
  
    methods: {
      toPage(e){
          this.$router.push('/'+e)
      },
      login(){
          this.$refs.ruleFormRef.validate(valid => {
              if(valid) {
                this.$refs.ruleFormRef.validate(valid => {
            if(valid) {
                let Email = this.ruleForm.email
                let password = this.ruleForm.password
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios({
                    url: 'https://www.tzgkczp.com/admin/web/register?name=' + Email + '&webName=' + 'g2g' + '&password=' + password,
                    method:'get',
                    data:{},
                    headers: {
                        'Content-type':'application/json;charset=utf-8 '
                    }
                }).then(res=>{
                    if(res.data.code==200){
                       this.$Message.success('Success')
                       let _this = this
                       setTimeout(() => {
                        _this.$router.push('/login')
                       }, 1500);
                       
                    }else{
                       
                        this.$Message.error('User does not exist or password is incorrect')
                    }
                    loading.close()
                }).catch(()=>{
                    loading.close()
                })
            }else{
                console.log('err')
            }
        })
              }else{
                  console.log('err')
              }
          })
      }
    },
  };
  </script>
  <style lang="scss" scoped>
  .login{
      
      .login_header{
          background: linear-gradient(270deg, rgb(84, 138, 247) 0%, rgb(0, 69, 208) 100%);
          height: 250px;
          width:100%; 
      
      }
      .logo_icon{
          margin-top:48px;
          height: 40px;
          
      }
      .login_container{
        position:relative;
        width:  500px;
        max-width: 100%;
        top:-120px;
        z-index: 99;
        background-color: #fff;
        border-radius: 10px;
        margin:0 auto;
        .login_thumb{
          padding:24px 48px;
          .login_title{
             font-size: 24px;
             font-weight: 500; 
             margin-bottom:48px;
          }
          .login_input{
              margin-bottom:30px;
              // /deep/.el-form-item{
              //     display: flex;
              //     flex-direction: column;
              //     text-align: left;
              // }
              /deep/.el-form-item__label{
                  text-align: left!important;
                  width: 100%;
                  font-size:12px!important;
                  padding-bottom:5px;
                  color:#777;
                  line-height: normal;
              }
              /deep/.el-form-item__content{
                  margin-left: 0 !important;;
              }
              .input_label{
                  color:#777;
                  font-size: 13px;
                  margin-bottom:4px;
                  text-align: left;
              }
              .input_cont{
                  width:100%;
                  /deep/.el-input__inner{
                      height:48px;
                      border-radius: 10px;
                      background:#fff;
                      font-size:12px;
                  }
                  /deep/.el-input{
                      font-size:12px;
                  }
              }
          }
          .btn_group{
              margin:48px 0 20px;
              >div{
     
              width:50%;
              border-radius: 4px;
              &:first-child{
                  margin-right:16px;
              }
              }
          }
          .login_up{
              padding:10px 0;
              border-top:1px solid rgba(0,0,0,0.12);
              font-size:13px;
              >span{
                  text-decoration: underline;
              }
          }
        }
      }
  }
  </style>